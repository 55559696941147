import {
  container,
  title,
  main,
  mainRaised
} from "assets/jss/material-kit-pro-react.jsx";

const landingPageStyle = theme => ({
  container: {
    color: "#FFFFFF",
    ...container,
    zIndex: "2"
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none",
    textAlign: "center",
    width: "100%",
  },
  subtitle: {
    fontSize: "1.1rem",
    maxWidth: "500px",
    margin: "10px auto 0",
    textAlign: "center",
    width: "100%",
    marginBottom: "80px"
  },
  main: {
    ...main
  },
  mainRaised: {
    ...mainRaised
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  },
  logo: {
    width: "100%"
  },
  logoContainer: {
    textAlign: "center",
  },
  wordCloudItem: {
    "position": "absolute",
    "color": "rgb(255, 255, 255)",
    "fontStyle": "normal",
    "textAlign": "center",
    "whiteSpace": "nowrap",
    "transformOrigin": "center bottom",
    "fontWeight": "lighter",
    "fontFamily": "Calibri Light, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
  },
  wordCloud: {
    height: "400px",
    [theme.breakpoints.down('lg')]: {
      width: "300px",
    },
    [theme.breakpoints.up('lg')]: {
      width: "100%",
    },
  },
  wordCloudContainer: {
    display: "flex", 
    alignContent: "center", 
    justifyContent: "center"
  }
});

export default landingPageStyle;
