import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.jsx";

const techSectionStyles = theme => ({
  ...tooltipsStyle,
  techLogo: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      maxHeight: "120px",
      maxWidth: "120px",
    }
  }
});

export default techSectionStyles;
