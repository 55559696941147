import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

import SectionTitle from "components/SectionTitle";
import techSectionStyles from "assets/jss/techSectionStyles.jsx";

// import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const technologies = [
  // { name: null, logo: null },
  { name: "HTML 5", logo: require("assets/img/tech/HTML5.svg") },
  { name: "CSS 3", logo: require("assets/img/tech/CSS3.svg") },
  { name: "SCSS", logo: require("assets/img/tech/sass.svg") },
  { name: "JavaScript", logo: require("assets/img/tech/javascript.svg") },
  { name: "TypeScript", logo: require("assets/img/tech/typescript.svg") },
  { name: "Electron", logo: require("assets/img/tech/electron.svg") },
  { name: "React", logo: require("assets/img/tech/react.svg") },
  { name: "Redux", logo: require("assets/img/tech/redux.svg") },
  { name: "Angular", logo: require("assets/img/tech/angular.svg") },
  { name: "Node.js", logo: require("assets/img/tech/nodejs.svg") },
  { name: "Babel", logo: require("assets/img/tech/babel.svg") },
  { name: "webpack", logo: require("assets/img/tech/webpack.svg") },
  { name: "Ethereum", logo: require("assets/img/tech/ethereum.svg") },
  { name: "Solidity", logo: require("assets/img/tech/solidity.svg") },
  { name: "iOS", logo: require("assets/img/tech/iOS.svg") },
  {
    name: "Swift",
    logo: require("assets/img/tech/swift.svg"),
    style: { minHeight: "80%" }
  },
  { name: "SQL Server", logo: require("assets/img/tech/sqlserver.svg") },
  { name: "MongoDB", logo: require("assets/img/tech/mongodb.svg") },
  { name: ".NET", logo: require("assets/img/tech/net.svg") },
  { name: "C#", logo: require("assets/img/tech/csharp.svg") },
  { name: "PowerShell", logo: require("assets/img/tech/powershell.png") },
  { name: "C", logo: require("assets/img/tech/c.svg") },
  { name: "Jenkins", logo: require("assets/img/tech/jenkins.svg") },
  { name: "git", logo: require("assets/img/tech/git.svg") },
  { name: "Azure", logo: require("assets/img/tech/azure.svg") },
  { name: "docker", logo: require("assets/img/tech/docker.svg") },
  { name: "Atlassian", logo: require("assets/img/tech/atlassian.svg") },
  { name: "Office 365", logo: require("assets/img/tech/office-365.svg") },
  { name: "Agile", logo: require("assets/img/tech/agile.png") },
  { name: "Scrum", logo: require("assets/img/tech/csm.png") },
  { name: "Kanban", logo: require("assets/img/tech/kanban.png") },
  { name: "PMP\u00AE", logo: require("assets/img/tech/pmpmp.png") }
];

class TechSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: technologies.map(() => false)
    };
  }

  handleTooltipClose = index => () => {
    const tooltipOpen = [...this.state.tooltipOpen];
    tooltipOpen[index] = false;
    this.setState({ tooltipOpen });
  };

  handleTooltipOpen = index => () => {
    const tooltipOpen = [...this.state.tooltipOpen];
    tooltipOpen[index] = true;
    this.setState({ tooltipOpen });
  };
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <SectionTitle title="Expertise" />
        <GridContainer justify="center" spacing={40}>
          {technologies.map((technology, index) => (
            <GridItem xs={6} sm={6} md={4} lg={2} key={index}>
              {technology.logo ? (
                <div key={index} className={classes.techLogo}>
                  <Tooltip
                    id={`tech-tooltip-${index}`}
                    title={technology.name}
                    placement="bottom"
                    enterDelay={100}
                    enterTouchDelay={0}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <img
                      src={technology.logo}
                      alt={technology.name}
                      style={technology.style}
                    />
                  </Tooltip>
                </div>
              ) : null}
            </GridItem>
          ))}
        </GridContainer>
      </React.Fragment>
    );
  }
}
export default withStyles(techSectionStyles)(TechSection);
