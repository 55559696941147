import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";

import teamStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/teamStyle.jsx";

import adrianIurov from "assets/img/team/adrian-iurov.jpg";
import alinaBlaj from "assets/img/team/alina-blaj.jpg";
import alinaZagan from "assets/img/team/alina-zagan.jpg";
import bogdanSafta from "assets/img/team/bogdan-safta.jpg";
import SectionTitle from "components/SectionTitle";

class TeamSection extends React.Component {
  render() {
    const { classes } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );
    const team = [
      {
        name: "Adrian Iurov",
        image: adrianIurov,
        role: "Agile PM / Full Stack Engineer",
        linkedin: "https://www.linkedin.com/in/adrianiurov/"
      },
      {
        name: "Alina Blaj",
        image: alinaBlaj,
        role: "Full Stack Engineer",
        linkedin: "https://www.linkedin.com/in/alina-blaj-b366b687/"
      },
      {
        name: "Bogdan Safta",
        image: bogdanSafta,
        role: "Full Stack Engineer",
        linkedin: "https://www.linkedin.com/in/bsafta/"
      },
      {
        name: "Alina Zăgan",
        image: alinaZagan,
        role: "Full Stack Engineer",
        linkedin: "https://www.linkedin.com/in/alina-z%C4%83gan-935526a7/"
      }
    ];

    return (
      <div className={classes.section}>
        <SectionTitle title="This is us" />

        <div>
          <GridContainer justify="center">
            {team.map((person, index) => (
              <GridItem xs={12} sm={12} md={4} key={index}>
                <Card plain>
                  <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                    <img
                      src={person.image}
                      alt="..."
                      className={imageClasses}
                    />
                  </GridItem>
                  <h4 className={classes.cardTitle}>
                    {person.name}
                    <br />
                    <small
                      className={classNames(
                        classes.smallTitle,
                        classes.socialTitle
                      )}
                    >
                      <span>{person.role}</span>
                      <a
                        href={person.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className={classes.linkedin + " fab fa-linkedin"} />
                      </a>
                    </small>
                  </h4>
                </Card>
              </GridItem>
            ))}
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(teamStyle)(TeamSection);
