import React from "react";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import LocationOn from "@material-ui/icons/LocationOn";
import { Hidden } from "@material-ui/core";

import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.jsx";

import TeamSection from "./Sections/TeamSection.jsx";
import WorkSection from "./Sections/WorkSection.jsx";
// import SkillsSection from "./Sections/SkillsSection.jsx";
import TechSection from "./Sections/TechSection.jsx";
// import ServicesSection from "./Sections/ServicesSection.jsx";
import horizontalLogo from "assets/img/meyvn-horizontal.png";
import bg from "assets/img/bg.jpg";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.contactRef = React.createRef();
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          logo={horizontalLogo}
          showOnlyOnScroll={true}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <Parallax filter="dark" image={bg}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={8} sm={6} md={5} lg={4} xl={4} className={classes.logoContainer}>
                <img src={horizontalLogo} className={classes.logo} alt="logo" />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <h4 className={classes.subtitle}>
                  Bringing your ideas to life
                </h4>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={6} md={12} className={classes.wordCloudContainer}>
                <Hidden mdDown>
                  <div className={classes.wordCloud}>
                    <div className={classes.wordCloudItem} style={{ "fontSize": "23px", "opacity": "0.5", "width": "384px", "transform": "translate(90px, 40px)  rotate(0deg)" }}>full focus on one project at a time</div>
                    <div className={classes.wordCloudItem} style={{ "fontSize": "20px", "opacity": "0.7", "width": "448px", "transform": "translate(600px, 50px) rotate(0deg)" }}>tailored servicing for each customer</div>
                    <div className={classes.wordCloudItem} style={{ "fontSize": "26px", "opacity": "0.8", "width": "224px", "transform": "translate(190px, 151px) rotate(0deg)" }}>striving for simplicity</div>
                    <div className={classes.wordCloudItem} style={{ "fontSize": "29px", "opacity": "0.9", "width": "224px", "transform": "translate(460px, 125px) rotate(0deg)" }}>Lean thinking</div>
                    <div className={classes.wordCloudItem} style={{ "fontSize": "29px", "opacity": "0.7", "width": "384px", "transform": "translate(650px, 167px) rotate(0deg)" }}>cross functional</div>
                    <div className={classes.wordCloudItem} style={{ "fontSize": "28px", "opacity": "0.9", "width": "256px", "transform": "translate(130px, 280px) rotate(0deg)" }}>blended T-shaped skills</div>
                    <div className={classes.wordCloudItem} style={{ "fontSize": "37px", "opacity": "0.5", "width": "256px", "transform": "translate(380px, 220px)  rotate(0deg)" }}>cohesive team</div>
                    <div className={classes.wordCloudItem} style={{ "fontSize": "19px", "opacity": "0.8", "width": "672px", "transform": "translate(500px, 300px) rotate(0deg)" }}>background in enterprise and start-ups</div>
                  </div>
                </Hidden>
                <Hidden lgUp>
                  <div className={classes.wordCloud}>
                    <div className={classes.wordCloudItem} style={{ "fontSize": "16px", "opacity": "0.5", "width": "215px", "transform": "translate(0px, 0px)  rotate(0deg)" }}>full focus on one project at a time</div>
                    <div className={classes.wordCloudItem} style={{ "fontSize": "12px", "opacity": "0.7", "width": "180px", "transform": "translate(115px, 40px) rotate(0deg)" }}>tailored servicing for each customer</div>
                    <div className={classes.wordCloudItem} style={{ "fontSize": "18px", "opacity": "0.8", "width": "150px", "transform": "translate(10px, 120px) rotate(0deg)" }}>striving for simplicity</div>
                    <div className={classes.wordCloudItem} style={{ "fontSize": "20px", "opacity": "0.9", "width": "155px", "transform": "translate(60px, 80px) rotate(0deg)" }}>Lean thinking</div>
                    <div className={classes.wordCloudItem} style={{ "fontSize": "20px", "opacity": "0.7", "width": "130px", "transform": "translate(152px, 155px) rotate(0deg)" }}>cross functional</div>
                    <div className={classes.wordCloudItem} style={{ "fontSize": "19px", "opacity": "0.9", "width": "190px", "transform": "translate(10px, 200px) rotate(0deg)" }}>blended T-shaped skills</div>
                    <div className={classes.wordCloudItem} style={{ "fontSize": "30px", "opacity": "0.5", "width": "190px", "transform": "translate(100px, 240px)  rotate(0deg)" }}>cohesive team</div>
                    <div className={classes.wordCloudItem} style={{ "fontSize": "13px", "opacity": "0.8", "width": "300px", "transform": "translate(0px, 280px) rotate(0deg)" }}>background in enterprise and start-ups</div>
                  </div>
                </Hidden>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax >
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <TeamSection />
            {/* <SkillsSection /> */}
            <TechSection />
            {/* <ServicesSection contactRef={this.contactRef} /> */}
            <WorkSection contactRef={this.contactRef} />
          </div>
        </div>
        <Footer
          content={
            <div>
              <div className={classes.center}>&copy; Meyvn {1900 + new Date().getYear()} <LocationOn className={classes.icon}></LocationOn> Brașov, Romania</div>
            </div>
          }
        />
      </div >
    );
  }
}

export default withStyles(landingPageStyle)(LandingPage);
