import React from "react";
import { Formik } from "formik";
import * as Yup from 'yup';
import Reaptcha from 'reaptcha';
import axios from 'axios';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import SectionTitle from "components/SectionTitle";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Check from "@material-ui/icons/Check";
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';

import workStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/workStyle.jsx";

class WorkSection extends React.Component {
  recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_KEY;
  contactValidationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too short!')
      .max(50, 'Too long!')
      .required('Required *'),
    email: Yup.string()
      .email('Invalid email!')
      .required('Required *'),
    message: Yup.string()
      .required('Required *'),
  });

  contactInitialValues = { email: '', name: '', message: '' };

  state = {
    openSnackbar: false,
    messageSentSuccesfully: false,
  };

  constructor(props) {
    super(props);
    this.form = null;
    this.recaptchaRef = null;

    this.onSubmit = this.onSubmit.bind(this);
    this.executeReCaptcha = this.executeReCaptcha.bind(this);
  }

  handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ openSnackbar: false });
  };

  onSubmit(values, { resetForm }) {
    const mailData = {
      ...values,
      mailKey: process.env.REACT_APP_MAIL_KEY
    };

    const baseUrl = process.env.REACT_APP_FIREBASE_FUNCTIONS;
    axios.post(`${baseUrl}/contact`, mailData)
      .then(() => {
        this.recaptchaRef.reset();
        resetForm();
        this.setState({ openSnackbar: true, messageSentSuccesfully: true });
      })
      .catch(function (error) {
        console.error(error);
        this.setState({ openSnackbar: true, messageSentSuccesfully: false });
      });
  }

  executeReCaptcha() {
    this.recaptchaRef.execute();
  }

  render() {
    const { classes, contactRef } = this.props;
    return (
      <div className={classes.section} >
        <GridContainer justify="center">
          <GridItem cs={12} sm={12} md={8}>
            <SectionTitle title="Contact us" />
            {/* <h4 className={classes.description}>
              Divide details about your product or agency work into parts. Write
              a few lines about each one and contact us about any further
              collaboration. We will responde get back to you in a couple of
              hours.
            </h4> */}
            <Formik initialValues={this.contactInitialValues} validationSchema={this.contactValidationSchema} onSubmit={this.onSubmit}>
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, validateForm, isValid, setTouched }) => (
                <form ref={ref => this.form = ref} onSubmit={handleSubmit}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput ref={contactRef} id="name" labelText={errors.name ? errors.name : "Your Name *"}
                        error={errors.name !== undefined && touched.name} success={errors.name === undefined && touched.name}
                        formControlProps={{
                          fullWidth: true,
                        }}

                        inputProps={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                          value: values.name,
                          disabled: isSubmitting
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput id="email" labelText={errors.email ? errors.email : "Your Email *"}
                        error={errors.email !== undefined && touched.email} success={errors.email === undefined && touched.email}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: handleChange,
                          onBlur: handleBlur,
                          value: values.email,
                          disabled: isSubmitting
                        }}
                      />
                    </GridItem>
                    <CustomInput id="message" labelText={errors.message ? errors.message : "Your Message *"}
                      error={errors.message !== undefined && touched.message} success={errors.message === undefined && touched.message}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.textArea,
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5,
                        onChange: handleChange,
                        onBlur: handleBlur,
                        value: values.message,
                        disabled: isSubmitting
                      }}
                    />
                    <Reaptcha ref={ref => (this.recaptchaRef = ref)} sitekey={this.recaptchaSiteKey} size="invisible"
                      onVerify={(response) => {
                        setFieldValue("recaptcha", response);
                        handleSubmit();
                      }}
                    />
                    <GridItem xs={12} sm={12} md={12} className={classes.textCenter}>
                      <Button color="primary" disabled={isSubmitting} onClick={() => {
                        validateForm()
                          .then(() => {
                            setTouched({ name: true, email: true, message: true });
                            if (isValid) {
                              this.executeReCaptcha()
                            }
                          });
                      }}>Send Message</Button>
                      {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </GridItem>
                  </GridContainer>

                </form>
              )}
            </Formik>
          </GridItem>
        </GridContainer>
        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={this.state.openSnackbar} onClose={this.handleSnackbarClose}
          classes={{ root: classes.snackbar }} autoHideDuration={4000}>
          {this.state.messageSentSuccesfully ?
            (<SnackbarContent message={<span><b>SUCCESS:</b> The message was sent succesfully!</span>} onClose={this.handleSnackbarClose} close color="success" icon={Check} />) :
            (<SnackbarContent message={<span><b>DANGER</b> The message failed to be delivered!</span>} onClose={this.handleSnackbarClose} close color="danger" icon="info_outline" />)}

        </Snackbar>
      </div >
    );
  }
}

export default withStyles(workStyle)(WorkSection);
